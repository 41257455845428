import homeIconBeach from '~/images/theme/beach/icon_home.svg'
import homeIconActiveBeach from '~/images/theme/beach/icon_home_toggle.svg'
import gameIconBeach from '~/images/theme/beach/button_game.svg'
import newsIconBeach from '~/images/theme/beach/icon_promotion.svg'
import newsIconActiveBeach from '~/images/theme/beach/icon_promotion_toggle.svg'

import homeIconCircus from '~/images/theme/circus/icon_home.svg'
import homeIconActiveCircus from '~/images/theme/circus/icon_home_toggle.svg'
import gameIconCircus from '~/images/theme/circus/button_game.svg'
import newsIconCircus from '~/images/theme/circus/icon_promotion.svg'
import newsIconActiveCircus from '~/images/theme/circus/icon_promotion_toggle.svg'

import homeIconDesert from '~/images/theme/desert/icon_home.svg'
import homeIconActiveDesert from '~/images/theme/desert/icon_home_toggle.svg'
import gameIconDesert from '~/images/theme/desert/button_game.svg'
import newsIconDesert from '~/images/theme/desert/icon_promotion.svg'
import newsIconActiveDesert from '~/images/theme/desert/icon_promotion_toggle.svg'

import homeIconForest from '~/images/theme/forest/icon_home.svg'
import homeIconActiveForest from '~/images/theme/forest/icon_home_toggle.svg'
import gameIconForest from '~/images/theme/forest/button_game.svg'
import newsIconForest from '~/images/theme/forest/icon_promotion.svg'
import newsIconActiveForest from '~/images/theme/forest/icon_promotion_toggle.svg'

import homeIconSnow from '~/images/theme/snow/icon_home.svg'
import homeIconActiveSnow from '~/images/theme/snow/icon_home_toggle.svg'
import gameIconSnow from '~/images/theme/snow/button_game.svg'
import newsIconSnow from '~/images/theme/snow/icon_promotion.svg'
import newsIconActiveSnow from '~/images/theme/snow/icon_promotion_toggle.svg'

import hotBeach from "~/images/theme/beach/icon_hot.svg";
import hitBeach from "~/images/theme/beach/icon_hit.svg";
import recommendBeach from "~/images/theme/beach/icon_recommend.svg";
import newBeach from "~/images/theme/beach/icon_new.svg";

import hotCircus from "~/images/theme/circus/icon_hot.svg";
import hitCircus from "~/images/theme/circus/icon_hit.svg";
import recommendCircus from "~/images/theme/circus/icon_recommend.svg";
import newCircus from "~/images/theme/circus/icon_new.svg";

import hotDesert from "~/images/theme/desert/icon_hot.svg";
import hitDesert from "~/images/theme/desert/icon_hit.svg";
import recommendDesert from "~/images/theme/desert/icon_recommend.svg";
import newDesert from "~/images/theme/desert/icon_new.svg";

import hotForest from "~/images/theme/forest/icon_hot.svg";
import hitForest from "~/images/theme/forest/icon_hit.svg";
import recommendForest from "~/images/theme/forest/icon_recommend.svg";
import newForest from "~/images/theme/forest/icon_new.svg";

import hotSnow from "~/images/theme/snow/icon_hot.svg";
import hitSnow from "~/images/theme/snow/icon_hit.svg";
import recommendSnow from "~/images/theme/snow/icon_recommend.svg";
import newSnow from "~/images/theme/snow/icon_new.svg";

export const iconWeb = {
  beach: {
    home: {
      normal: homeIconBeach,
      active: homeIconActiveBeach
    },
    game: {
      normal: gameIconBeach,
      active: gameIconBeach,
    },
    news: {
      normal: newsIconBeach,
      active: newsIconActiveBeach,
    },
    new: newBeach,
    hot: hotBeach,
    hit: hitBeach,
    recommend: recommendBeach,
  },
  circus: {
    home: {
      normal: homeIconCircus,
      active: homeIconActiveCircus
    },
    game: {
      normal: gameIconCircus,
      active: gameIconCircus,
    },
    news: {
      normal: newsIconCircus,
      active: newsIconActiveCircus,
    },
    new: newCircus,
    hot: hotCircus,
    hit: hitCircus,
    recommend: recommendCircus,
  },
  desert: {
    home: {
      normal: homeIconDesert,
      active: homeIconActiveDesert
    },
    game: {
      normal: gameIconDesert,
      active: gameIconDesert,
    },
    news: {
      normal: newsIconDesert,
      active: newsIconActiveDesert,
    },
    new: newDesert,
    hot: hotDesert,
    hit: hitDesert,
    recommend: recommendDesert,
  },
  forest: {
    home: {
      normal: homeIconForest,
      active: homeIconActiveForest
    },
    game: {
      normal: gameIconForest,
      active: gameIconForest,
    },
    news: {
      normal: newsIconForest,
      active: newsIconActiveForest,
    },
    new: newForest,
    hot: hotForest,
    hit: hitForest,
    recommend: recommendForest,
  },
  snow: {
    home: {
      normal: homeIconSnow,
      active: homeIconActiveSnow
    },
    game: {
      normal: gameIconSnow,
      active: gameIconSnow,
    },
    news: {
      normal: newsIconSnow,
      active: newsIconActiveSnow,
    },
    new: newSnow,
    hot: hotSnow,
    hit: hitSnow,
    recommend: recommendSnow,
  }
}

export default iconWeb