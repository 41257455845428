import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import RoundCheckBox from "~/components/round-checkbox"

const Input = styled.input`
  width: 100%;
  height: 51px;
  background: #fff;
  border: 0.5px solid #707070;
  border-radius: 6px;
  backdrop-filter: blur(30px);
  padding: 0 27px;
  margin-top: 18px;
`

const Textarea = styled.textarea`
  width: 100%;
  height: 136px;
  background: #fff;
  border: 0.5px solid #707070;
  border-radius: 6px;
  backdrop-filter: blur(30px);
  padding: 12px 27px;
  margin-top: 18px;
`

const Button = styled.button`
  font-size: 19px;
  width: 100%;
  height: 48px;
  background: #13c750;
  border-radius: 6px;
  color: #fff;
  text-transform: uppercase;
  margin-top: 18px;
`

const Content = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  z-index: 21;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.7px);
  -webkit-backdrop-filter: blur(9.7px);
  border: 1px solid rgba(0, 0, 0, 0.3);

  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`

const Line = styled.div`
  margin: 36px 0 18px;

  img {
    width: 31px;
    height: 30px;
    margin-right: 10px;
  }
`

const Support = ({ langKey, handleSupport }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: {collection: {eq: "common"}}) {
        edges {
          node {
            file
            frontmatter {
              contact_title
              contact_line
              contact_line_url
              contact_or
              contact_name
              contact_phone
              contact_email
              contact_message
              contact_agree
              contact_submit
            }
          }
        }
      }
      contact: allMarkdownRemark(filter: {collection: {eq: "contact"}}) {
        edges {
          node {
            id
            frontmatter {
              social_logo
              social_name
              social_contact
            }
          }
        }
      }
    }
  `)
  const {
    node: { frontmatter },
  } = data.allMarkdownRemark.edges
    .find(item => item.node.file
      .split(".")[1]
      .replace("/", "") === langKey)

  const contactList = data.contact.edges
  return (
    <Content>
      <div className="support-wrapper">
        <div className="flex items-center justify-between title">
          <div>{frontmatter.contact_title}</div>
          <button onClick={() => handleSupport(false)}>
            <div className="close"></div>
          </button>
        </div>
        <div className='inline-flex justify-evenly'>
          {contactList.length && contactList.map(items => (
            <a href={items.node.frontmatter.social_contact} target="_blank" rel="noreferrer">
              <Line className="flex items-center">
                <img src={items.node.frontmatter.social_logo} alt="" />
              </Line>
            </a>
          ))}
        </div>
        <div className="text-center or">{frontmatter.contact_or}</div>
        <form
          name="Contact Form"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="Contact Form" />
          <input type="hidden" name="bot-field" />
          <div>
            <Input type="text" name="name" placeholder={frontmatter.contact_name} required />
          </div>
          <div>
            <Input type="text" name="phone" placeholder={frontmatter.contact_phone} required />
          </div>
          <div>
            <Input type="email" name="email" placeholder={frontmatter.contact_email} required />
          </div>
          <div>
            <Textarea name="message" placeholder={frontmatter.contact_message} required />
          </div>
          <div>
            <RoundCheckBox
              text={frontmatter.contact_agree}
              isFront={true}
            />
          </div>
          <Button>{frontmatter.contact_submit}</Button>
        </form>
      </div>
    </Content>
  )
}

export default Support
