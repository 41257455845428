import React from 'react';
import { Link } from "gatsby"
import styled from 'styled-components';
import PlayUrlContext from "~/context/play-url"
import Toggle from "~/components/dark-mode"
import flagEN from "~/images/flag_en.png"
import flagTH from "~/images/flag_th.png"

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--bg-primary);
  z-index: 19;
  padding: 60px 20px;
  transition: 0.3s all;
  visibility: hidden;
  opacity: 0;
  color: var(--font-primary);

  h2 {
    color: var(--font-primary);
  }

  &.-active {
    visibility: visible;
    opacity: 1;
  }
`

const SettingBox = styled.div`
  margin: 10px 0px;
  padding: 10px;
  border-radius: 8px;
  background: var(--light-secondaryBg);
`

const LangButton = styled.div`
  display: flex;
  justify-content: space-evenly;
  color: var(--font-primary) !important;
  width: 58px;
  height: 35px;
  border-radius: 6px;
  backdrop-filter: blur(30px);
  font-size: 14px;
  padding-left: 20px;
  cursor: pointer;
`


export default function SidebarMobile({ langKey, langsMenu }) {
  const { isShowMenuMobile } = React.useContext(PlayUrlContext)

  return (
    <Wrapper className={`${isShowMenuMobile ? '-active' : ''}`}>
      <h2>Setting</h2>
      <SettingBox>
        <div className='flex justify-between p-5'>
          Mode
          <Toggle />
        </div>
        <hr />
        <div className='flex justify-between p-5'>
          Languages
          <Link to={langsMenu.find(item => !item.selected).link} className="flex items-center">
            <LangButton
              className="flex items-center justify-center"
              style={{
                backgroundImage: `url(${langKey === "th" ? flagTH : flagEN})`,
                backgroundSize: "20px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "8px center",
              }}
            >
              {langKey.toUpperCase()}
            </LangButton>
          </Link>
        </div>
      </SettingBox>
    </Wrapper>
  )
}