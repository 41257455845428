import React from "react"
import styled from 'styled-components'

const Button = styled.div`
  width: fit-content;
  height: 35px;
  max-height: 53px;
  background: transparent linear-gradient(248deg, var(--color-secondary) 0%, var(--color-primary) 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  font-size: 16px;
  z-index: 21;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #FFF;
  padding: 5px;
`

const IconNav = styled.i`
  background: var(--color-main);
  mask: url(${props => props.icon}) no-repeat center / contain;
  -webkit-mask: url(${props => props.icon}) no-repeat center / contain;
  width: 25px;
  height: ${props => props.height};
  margin-right: 3px;
`

export default function ButtonCard({ text, onPress }) {
  return (
    <Button onClick={onPress}>
      <IconNav
        icon={`/images/icon_game.svg`}
        width={`28px`}
        height={`18px`}
      />
      <div>{text}</div>
    </Button>
  )
}
