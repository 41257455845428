import React from "react"
import PlayUrlContext from "~/context/play-url"
import styled from 'styled-components'
import RoundCheckBox from "~/components/round-checkbox"

const FilterPanel = styled.div`
  font-size: 14px;
  padding: 1rem 2rem;
  color: var(--font-primary);
  transition: 0.6s all, color 0s;
  visibility: hidden;
  opacity: 0;
  width: var(--sidebar-width-inactive);

  &.-showMenu {
    width: var(--sidebar-width);
    visibility: visible;
    opacity: 1;
  }

  @media screen and (max-width: 768px) {
    opacity: 1;
    width: 100%;
    visibility: visible;
    margin-top: 60px;
  }
`

const CheckBoxList = styled.div`
  font-size: 12px;
`

const CheckBoxHeading = styled.div`
  border-top: 0.5px solid #7e7e7e;
  margin-top: 12px;
`

export function FilterGameComponent({ varFilter }) {
  const { isShowMenu } = React.useContext(PlayUrlContext)

  return (
    <>
      {varFilter && (
        <FilterPanel className={isShowMenu ? '-showMenu' : ''}>
          <div className="font-bold">{varFilter.frontmatter.filters}</div>
          <CheckBoxList>
            <CheckBoxHeading className="font-bold">
              <RoundCheckBox
                text={`Category`}
                handleCheck={varFilter.handleCheck}
                isSome={varFilter.isSomeCate}
                check={varFilter.selected["Cate"].length === varFilter.listCate.length}
                typeClass="Cate"
              />
            </CheckBoxHeading>
            {varFilter.listCate.map((value, index) => (
              <RoundCheckBox
                text={value.text}
                key={index}
                handleCheck={varFilter.handleCheck}
                check={varFilter.selected["Cate"].some(
                  item => item.text === value.text
                )}
                typeClass="Cate"
                isFront={true}
              />
            ))}
          </CheckBoxList>
          <CheckBoxList>
            <CheckBoxHeading>
              <RoundCheckBox
                text={`Provider`}
                handleCheck={varFilter.handleCheck}
                isSome={varFilter.isSomeProv}
                check={varFilter.selected["Prov"].length === varFilter.listProv.length}
                typeClass="Prov"
              />
            </CheckBoxHeading>
            {varFilter.listProv.map((value, index) => (
              <RoundCheckBox
                text={value}
                key={index}
                handleCheck={varFilter.handleCheck}
                check={varFilter.selected["Prov"].some(item => item === value)}
                typeClass="Prov"
                isFront={true}
              />
            ))}
          </CheckBoxList>
          <CheckBoxList>
            <CheckBoxHeading>
              <RoundCheckBox
                text={`Feature`}
                handleCheck={varFilter.handleCheck}
                isSome={varFilter.isSomeFeat}
                check={varFilter.selected["Feat"].length === varFilter.listFeat.length}
                typeClass="Feat"
              />
            </CheckBoxHeading>
            {varFilter.listFeat.map((value, index) => (
              <RoundCheckBox
                text={value}
                key={index}
                handleCheck={varFilter.handleCheck}
                check={varFilter.selected["Feat"].some(item => item === value)}
                typeClass="Feat"
                isFront={true}
              />
            ))}
          </CheckBoxList>
        </FilterPanel>
      )}
    </>
  )
}